/* ------------------------------------------ START LOGIN PAGE ------------------------------------------ */
.fadeintoout {
  animation-duration: 0.5s;
  animation-name: fadeintoout-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes fadeintoout-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeintoout.pop {
  animation-name: fadeintoout-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes fadeintoout-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* ---------------------------------------START MYTEAM MEMBERS CARDS ------------------------------------ */
/* TOP TO BOTTOM EFFECT */
@keyframes toptobottom {
  0% {
    top: -150px;
  }
  100% {
    top: 0;
  }
}

/* AVATAR IMAGE TOP TO BOTTOM EFFECT */
@keyframes avatarTopToBottom {
  0% {
    top: -150px;
  }
  100% {
    top: 41px;
  }
}

/* FADE IN MOVE LITELY */
@keyframes fadeInMove {
  0% {
    opacity: 0;
    left: -300px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
