body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ul {
  list-style-type: none;
}
li {
  list-style-type: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* FOR DISABLING THE AUTO FILL BACKGROUND COLOR */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/* FOR ACCESSIBILITY */
.accessibility {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  margin-top: -21px;
  margin-bottom: 33px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus {
  left: 10px;
  top: auto;
  width: 10%;
  margin-top: -21px;
  margin-bottom: 33px;
  height: auto;
  overflow: auto;
  margin: 0 35%;
  font-size: 15px;
  text-align: center;
  z-index: 999;
}
/* FOR DASHBOARD COURSE BREAK UP BY TEAM AND AVG LEARNING HOURS CHART SCROLLS */
.dashBoardchartWrapper {
  position: relative;
}
.dashBoardchartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.dashBoardchartAreaWrapper {
  height: 250px;
  overflow-y: auto;
}
/*---------------------*/
svg.custom .node circle {
  fill: #f3f3ff;
  stroke: #2593b8;
  stroke-width: 1.5px;
}

svg.custom .node text {
  font-size: 11px;
  background-color: #444;
  fill: #333;
}
svg.custom text {
  font-size: 11px;
}

svg.custom .node {
  cursor: pointer;
  font-size: 11px;
}

svg.custom path.link {
  fill: none;
  stroke: #49879c;
  stroke-width: 1.5px;
  font-size: 11px;
}
/*---course compare general----*/
#courseCompareNotice {
  position: absolute;
  bottom: -42px;
  right: 150px;
  padding: 10px;
  color: #fff;
  background-image: linear-gradient(to right, green, #6bc787);
  display: none;
  cursor: pointer;
}