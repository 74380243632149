/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Acumin Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Acumin Pro"),
    url("../assests/Fonts/AcuminPro/Acumin-RPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Light";
  font-style: normal;
  font-weight: 300;
  src: local("Acumin Pro Light"),
    url("../assests/Fonts/AcuminPro/Acumin-RPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Acumin Pro Italic"),
    url("../assests/Fonts/AcuminPro/Acumin-ItPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Acumin Pro Bold"),
    url("../assests/Fonts/AcuminPro/Acumin-BdPro.woff") format("woff");
}

@font-face {
  font-family: "Acumin Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Acumin Pro Bold Italic"),
    url("../assests/Fonts/AcuminPro/Acumin-BdItPro.woff") format("woff");
}
